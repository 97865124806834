import useFormHook from "@/hooks/useForm";
import { useMutation } from "@apollo/react-hooks";
import { default as classNames, default as cn } from "classnames";
import { getYear } from "date-fns";
import gql from "graphql-tag";
import { useRouter } from "next/router";
import React, { useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useRepairContext } from "src/contexts/RepairContext";
import { useUserContext } from "../contexts/UserContext";
import { formatDate, padLeft } from "../helpers/functions";
import Button from "./form/Button";
import Textarea from "./form/Textarea";
import { StatusChanger } from "./StatusChanger";
import { TableCell, TableHead, TableRow } from "./Table";
import { ArrowLeft, ArrowRight } from "heroicons-react";
import { Input } from "./tailwind-ui/Input";

type StatusLabelProps = {
  status: string;
};
export function StatusLabel(props: StatusLabelProps) {
  const { status } = props;
  return (
    <span
      className={classNames(
        "px-2 inline-flex text-xs leading-5 font-semibold rounded-full border-2",
        statusToTagKind(status)
      )}
    >
      {statusToText(status)}
    </span>
  );
}

export function statusToTagKind(status: string) {
  switch (status.toUpperCase()) {
    case "CREATED": {
      return "border-black text-black-800";
    }
    case "FINISHED": {
      return "border-black text-black-800";
    }
    default: {
      return "border-black text-black-800";
    }
  }
}

export function statusToText(status: string) {
  switch (status.toUpperCase()) {
    case "CREATED": {
      return "Angemeldet";
    }
    case "IN_TRANSPORT": {
      return "In\xa0Transport";
    }
    case "COST_ESTIMATE_AVAILABLE": {
      return "Angebot\xa0vorhanden";
    }
    case "RUNNING": {
      return "In\xa0Bearbeitung";
    }
    case "ACCEPTED": {
      return "Genehmigt";
    }
    case "IN_REPAIR": {
      return "Reparatur\xa0wird\xa0durchgeführt";
    }
    case "REJECTED": {
      return "Abgelehnt";
    }
    case "IRREPAIRABLE": {
      return "Unrentabel\xa0/\xa0Unreparierbar";
    }
    case "FINISHED": {
      return "Abgeschlossen";
    }
    default: {
      throw new Error("Unknown Status");
    }
  }
}

const UPDATE_REPAIR_NOTE = gql`
  mutation UpdateRepairNote($id: uuid!, $note: String!) {
    update_repair_by_pk(pk_columns: { id: $id }, _set: { note: $note }) {
      id
    }
  }
`;

const UPDATE_REPAIR_RECLAMATION = gql`
  mutation SetRepairReclamation($id: uuid!, $isReclamation: Boolean!) {
    update_repair_by_pk(pk_columns: { id: $id }, _set: { isReclamation: $isReclamation }) {
      id
    }
  }
`;

function RepairRow(props) {
  const { isAdmin, isColosseumManager } = useUserContext();
  const { refetchSingle } = useRepairContext();

  const [updateRepairNote, repairNoteResponse] = useMutation(UPDATE_REPAIR_NOTE);

  const [updateRepairIsReclamation] = useMutation(UPDATE_REPAIR_RECLAMATION);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    bodyClass: "scale-80 -m-16 transform printbody",
    content: () => componentRef.current,
  });
  const { user, OpenRowComponent } = props;
  const {
    id,
    repairNumber,
    costEstimates,
    invoice,
    status,
    trackingCode,
    returnTrackingCode,
    returnDate,
    invoiceURL,
    createdAt,
    approvedAt,
  } = props;
  //TODO: Refactor this to one to one relationship
  const costEstimate = costEstimates[0];
  const router = useRouter();
  const rowOpen = router.asPath.includes(`?open=${id}`);
  const setRowOpen = () => {
    router.replace(router.pathname, { search: !rowOpen ? `?open=${id}` : "" });
  };

  const { formValues, setFormValues, register, unregister, setValue } = useFormHook();

  const RegisterFormProps = { register, unregister, setValue, formValues };

  const year = getYear(new Date(createdAt)) - 2000;
  return (
    <>
      <TableRow className={cn({ "border-2 border-b-0 border-black": rowOpen })}>
        <TableCell>
          <div className="relative font-medium text-gray-900">
            <span className="relative inline-flex">
              {`R${year}-${padLeft(repairNumber, 4)}`}
              {status === "accepted" && isAdmin && (
                <span className="flex w-3 h-3 ml-2">
                  <span className="absolute inline-flex w-3 h-3 bg-pink-400 rounded-full opacity-75 animate-ping"></span>
                  <span className="relative inline-flex w-3 h-3 bg-pink-500 rounded-full"></span>
                </span>
              )}
              {isAdmin && user?.isColosseum && (
                <span className="flex w-3 h-3 ml-2">
                  <span className="absolute inline-flex w-3 h-3 bg-blue-400 rounded-full opacity-75 animate-ping"></span>
                  <span className="relative inline-flex w-3 h-3 bg-blue-500 rounded-full"></span>
                </span>
              )}
              {isAdmin && user?.isDesos && (
                <span className="flex w-3 h-3 ml-2">
                  <span className="absolute inline-flex w-3 h-3 bg-yellow-400 rounded-full opacity-75 animate-ping"></span>
                  <span className="relative inline-flex w-3 h-3 bg-yellow-500 rounded-full"></span>
                </span>
              )}
            </span>
          </div>
        </TableCell>
        {!isAdmin &&
          (invoice ? (
            <TableCell>
              <div className="font-medium text-gray-900">{invoice.invoiceNumber + 950000}</div>
            </TableCell>
          ) : (
            <TableCell>N/A</TableCell>
          ))}

        {(isAdmin || isColosseumManager) && (
          <TableCell>
            <div className="relative group">
              <div className="font-medium text-gray-900">{user.office}</div>
              <div className="text-gray-500">{`${user.customerNumber} ${user.firstName} ${user.lastName}`}</div>
              <div className="text-gray-900">{user.email}</div>
              {isAdmin && (
                <>
                  <div className="text-gray-900">{user.phone}</div>
                </>
              )}
            </div>
          </TableCell>
        )}

        {isAdmin && (
          <TableCell>
            <div className="text-gray-900">{user.country}</div>
          </TableCell>
        )}
        <TableCell>
          <StatusLabel status={status} />
        </TableCell>

        {isAdmin && (
          <TableCell>
            <div className="text-gray-900">{props.sentTo || "N/A"}</div>
          </TableCell>
        )}
        {returnTrackingCode || trackingCode ? (
          <TableCell>
            <div className="text-blue-500 underline">
              <a
                href={`http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${
                  returnTrackingCode || trackingCode
                }`}
                target="_blank"
              >
                {returnTrackingCode || trackingCode}
              </a>
            </div>
          </TableCell>
        ) : (
          <TableCell>N/A</TableCell>
        )}
        {isAdmin && (
          <TableCell>
            <div className="text-gray-900">{props.discountCode || "N/A"}</div>
          </TableCell>
        )}
        {isAdmin &&
          (!["finished", "irrepairable", "rejected"].includes(status) ? (
            <TableCell>
              <select
                className="appearance-none"
                onChange={async (e) => {
                  const isReclamation = e.target.value === "true";
                  await updateRepairIsReclamation({ variables: { id, isReclamation } });
                  refetchSingle(props.id);
                }}
                value={props.isReclamation ? "true" : "false"}
              >
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select>
            </TableCell>
          ) : (
            <TableCell>{props.isReclamation ? "Ja" : "Nein"}</TableCell>
          ))}
        <TableCell>
          <div className="text-gray-900">{formatDate(createdAt)}</div>
        </TableCell>
        {status === "finished" && (
          <TableCell>
            <div className="text-gray-900">{returnDate ? formatDate(returnDate) : "N/A"}</div>
          </TableCell>
        )}
        {(status === "rejected" || status === "irrepairable") && (
          <>
            <TableCell>N/A</TableCell>
            <TableCell>N/A</TableCell>
          </>
        )}
        {["finished", "irrepairable", "rejected"].includes(status) &&
          (status === "finished" ? (
            <>
              <TableCell>
                {costEstimate?.approvedAt ? (
                  <div className="text-gray-900">{formatDate(costEstimate?.approvedAt)}</div>
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell>
                <div className="text-gray-900">
                  {invoiceURL ? (
                    <div className="text-blue-500 underline">
                      <a href={invoiceURL} target="_blank">
                        Download
                      </a>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </div>
              </TableCell>
            </>
          ) : (
            <TableCell>N/A</TableCell>
          ))}
        {!(status === "finished" && !costEstimate) && (
          <TableCell className="pr-2 text-sm font-medium leading-5 border-b border-gray-200">
            <button type="button" onClick={() => setRowOpen()}>
              <svg
                className={classNames("fill-current h-6 w-6 transform", {
                  ["rotate-180"]: rowOpen,
                })}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </button>
          </TableCell>
        )}
      </TableRow>
      {rowOpen && (
        <TableRow className={cn({ "border-2 border-t-0 border-black": rowOpen })}>
          <TableCell colSpan={props.colSpan + 1} className="px-10 py-4">
            {isAdmin && (
              <div>
                <StatusChanger {...props} />
                <div className="flex -mx-4">
                  <div className="w-1/2 px-4">
                    <Textarea
                      id="repairNote"
                      label="Interne Information bzgl. Reparatur"
                      defaultValue={props.note}
                      {...RegisterFormProps}
                    />
                    <Button
                      className="block ml-auto"
                      onClick={async () => {
                        await updateRepairNote({ variables: { id, note: formValues.repairNote } });
                        refetchSingle(props.id);
                      }}
                    >
                      {repairNoteResponse.loading ? "Speichert..." : "Notiz speichern"}
                    </Button>
                  </div>
                  <div className="w-1/2 px-4">
                    {(props.isReclamation || props.additionalInfo) && (
                      <>
                        <p className="block my-2 font-semibold">Zusätzliche Infos vom Kunden</p>
                        {props.isReclamation && <p>Reklamationsnummer: {props.reclamationNumber ?? "N/A"}</p>}
                        <p>{props.additionalInfo}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            <span ref={componentRef}>
              <OpenRowComponent {...props} handlePrint={handlePrint} />
            </span>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

const RepairTable = (props) => {
  const { isAdmin, isColosseumManager } = useUserContext();
  const { repairs, OpenRowComponent, paginate = true } = props;
  const [page, setPage] = useState(1);
  const pageSize = props.pageSize || 10;
  const hasFinishedRepairs = useMemo(
    () => repairs.some((repair) => repair.status === "finished" || repair.status === "rejected"),
    [repairs]
  );

  if (props.loading) return <>Lädt...</>;
  if (!repairs.length) return <>Keine passenden Reparaturen für diese Kategorie gefunden</>;

  let colSpan = 5;
  isAdmin && (colSpan += 5);
  !isAdmin && (colSpan += 2);
  !isAdmin && hasFinishedRepairs && (colSpan += 1);
  hasFinishedRepairs && (colSpan += 2);

  const pages = Math.ceil(repairs.length / pageSize);
  const slicedRepairs =
    isAdmin && paginate ? repairs.slice(Math.max(page - 1, 0) * pageSize, (page + 1) * pageSize) : repairs;
  return (
    <div className="w-full px-6 mx-auto">
      {isAdmin && paginate && (
        <nav className="flex items-end justify-end px-4 py-2 border-b border-gray-200 sm:px-0">
          <div className="flex mr-4 -mt-px">
            <button
              className="inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 border-b-2 border-transparent outline-none hover:border-gray-300 hover:text-gray-700 focus:outline-none"
              onClick={() => {
                setPage(Math.max(page - 1, 1));
              }}
            >
              <ArrowLeft className="w-5 h-5 mr-3 text-gray-400" aria-hidden="true" />
              Vorherige Seite
            </button>
          </div>
          <div className="hidden md:-mt-px md:flex">
            {[...Array(pages)].map((_, index, arr) => {
              const isActive = page === index + 1;
              return (
                <button
                  key={index}
                  className={cn(
                    "inline-flex items-center border-b-2  px-4 pt-4 text-sm font-medium  outline-none focus:outline-none",
                    {
                      "border-indigo-500 text-indigo-600": isActive,
                      "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300": !isActive,
                    }
                  )}
                  onClick={() => {
                    setPage(index + 1);
                  }}
                >
                  {index + 1}
                </button>
              );
            })}
          </div>
          <div className="flex ml-4 -mt-px">
            <button
              className="inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 border-b-2 border-transparent outline-none hover:border-gray-300 hover:text-gray-700 focus:outline-none"
              onClick={() => {
                setPage(Math.min(page + 1, pages));
              }}
            >
              Nächste Seite
              <ArrowRight className="w-5 h-5 ml-3 text-gray-400" aria-hidden="true" />
            </button>
          </div>
        </nav>
      )}

      <div className="inline-block min-w-full align-middle border-2 border-gray-400 border-l-3 border-b-3">
        <table className="min-w-full">
          <thead>
            <tr>
              <TableHead label="Reparaturnr." />
              {!isAdmin && <TableHead label="Rechnungsnr." />}
              {(isAdmin || isColosseumManager) && <TableHead label="Kunde" />}
              {isAdmin && <TableHead label="Land" />}
              <TableHead label="Status" />
              {isAdmin && <TableHead label="Gesendet an" />}
              <TableHead label="Tracking Code" />
              {isAdmin && <TableHead label="Rabattcode" />}
              {isAdmin && <TableHead label="Ist Reklamation?" />}
              <TableHead label="Erstellt am" />
              {hasFinishedRepairs && <TableHead label="Zurückgesendet am" />}
              {hasFinishedRepairs && <TableHead label="Genehmigt am" />}
              {hasFinishedRepairs && <TableHead label="Rechnung" />}
              <TableHead />
            </tr>
          </thead>
          <tbody className="bg-white">
            {slicedRepairs.map((repair) => (
              <RepairRow key={repair.id} {...repair} OpenRowComponent={OpenRowComponent} colSpan={colSpan} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RepairTable;

import classNames from "classnames";
import React, { useState, useEffect } from "react";

export default function Select(props) {
  const { id, label, className, options, placeholder, disabled, withMisc } = props;

  const { register, unregister, setValue, required, errorMessage, value, defaultValue } = props;
  const [internalValue, setInternalValue] = useState(value || defaultValue);
  const [isValid, setIsValid] = useState(!required || value || defaultValue);
  const [isVisited, setIsVisited] = useState(false);
  const shouldShowError = !isValid && isVisited;

  const labelToValue = (label) => {
    const option = options.find((option) => option.label === label);
    if (!option && label === "Sonstiges") return "Sonstiges";
    return option ? option.value : "";
  };

  const valueToLabel = (_value) => {
    const option = options.find((option) => option.value === _value);
    if (!option && _value === "Sonstiges") return "Sonstiges";
    return option ? option.label : "";
  };

  useEffect(() => {
    register(id, value || internalValue);
    return () => unregister(id);
  }, [register, unregister, id, value, internalValue]);

  const onChange = (event) => {
    const selectedValue = event.target.value;
    const newValue = labelToValue(selectedValue);
    setInternalValue(newValue);
    setValue(id, newValue);
    setIsValid(true);
  };

  const withRequired = (string) => string + (required ? " *" : "");
  return (
    <div className={classNames(className, { "mb-4": !className?.includes("mb-") })}>
      <label className="block my-2 font-semibold">{withRequired(label)}</label>
      <div className="relative">
        <select
          disabled={disabled}
          className="w-full px-4 py-3 pr-8 bg-gray-100 border border-gray-400 appearance-none"
          onChange={onChange}
          onBlur={(e) => {
            setIsVisited(true);
            if (typeof props.onBlur === "function") props.onBlur(e, isValid);
          }}
          value={valueToLabel(internalValue)}
          required={required}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map(({ label }) => (
            <option key={label}>{label}</option>
          ))}
          {withMisc && <option value="Sonstiges">Sonstiges</option>}
        </select>
      </div>
      {internalValue === "Sonstiges" && (
        <input
          className="w-full px-2 py-3 bg-gray-100 border border-gray-400"
          onChange={(event) => {
            setValue(id, event.target.value);
          }}
          placeholder="Sonstiges eingeben"
        />
      )}
      {shouldShowError && <p className="text-red-400">{errorMessage}</p>}
    </div>
  );
}

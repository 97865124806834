import React, { useMemo, useState } from "react";
import classNames from "classnames";

export function TableHead(props) {
  const { label = "" } = props;
  return (
    <th className="px-3 py-4 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
      {label}
    </th>
  );
}

export function TableRow(props) {
  const { className, children } = props;
  return <tr className={className}>{children}</tr>;
}

export function TableCell(props) {
  return (
    <td className={classNames("px-3 py-2 border-b border-gray-200", "text-sm leading-5", props.className)} {...props}>
      {props.children}
    </td>
  );
}

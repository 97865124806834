import React from "react";
import classNames from "classnames";

export default function Button(props) {
  const { children, className = "" } = props;
  return (
    <button
      {...props}
      className={classNames(
        "py-3 px-4 rounded focus:ring-primary-dark text-white font-semibold",
        className,
        className.includes("bg-") ? "" : "bg-primary hover:bg-primary-dark",
        {
          ["opacity-50 cursor-not-allowed"]: props.disabled,
        }
      )}
    >
      {children}
    </button>
  );
}

import { InputProps } from "@/components/tailwind-ui/Input";
import { useState } from "react";
import { padLeft, ISOCountryToText } from "src/helpers/functions";

export const useRepairFilter = (repairs: any[]): [any[], Omit<InputProps, "label">] => {
  const [search, setSearch] = useState<string>("");

  const year = new Date().getFullYear() - 2000;
  const filteredRepairs = repairs.filter((repair) => {
    const filterStart = (string) => string?.includes(search);
    const matchedRepairNumber = `R${year}-${padLeft(repair.repairNumber, 4)}`.includes(search);
    const { user } = repair;
    const matchedOffice = filterStart(user.office);
    const matchedCustomerNumber = String(user.customerNumber) === search;
    const matchedName = filterStart(`${user.firstName} ${user.lastName}`);
    const matchedEmail = filterStart(user.email);
    const matchedStreet = filterStart(user.street);
    const matchedZip = filterStart(user.zip);
    const matchedCity = filterStart(user.city);
    const matchedCountry = filterStart(ISOCountryToText(user.country));
    return (
      matchedCustomerNumber ||
      matchedRepairNumber ||
      matchedOffice ||
      matchedName ||
      matchedEmail ||
      matchedStreet ||
      matchedZip ||
      matchedCity ||
      matchedCountry
    );
  });

  return [
    filteredRepairs,
    {
      value: search,
      onChange: (e) => setSearch(e.target.value),
    },
  ];
};

import React, { useState, useEffect } from "react";

export default function Textarea(props) {
  const { id, label, className } = props;
  const { register, unregister, setValue, required, validate, errorMessage, defaultValue, value } = props;
  const [internalValue, setInternalValue] = useState(value || defaultValue);
  const [isValid, setIsValid] = useState(!required);
  const [isVisited, setIsVisited] = useState(false);
  const shouldShowError = !isValid && isVisited;

  useEffect(() => {
    register(id, value || internalValue);
    return () => unregister(id);
  }, [register, unregister, id, internalValue]);

  const validateFunction = validate || (required && Boolean);
  const onChange = (event) => {
    const newValue = event.target.value;
    setInternalValue(newValue);
    setValue(id, newValue);
    if (validateFunction) setIsValid(validateFunction(newValue));
  };

  const withRequired = (string) => string + (required ? " *" : "");
  return (
    <div className={className + " mb-4"}>
      {label && <label className="block font-semibold my-2">{withRequired(label)}</label>}
      <textarea
        className="px-2 py-3 w-full h-32 bg-gray-100 border border-gray-400"
        id={id}
        onChange={onChange}
        onBlur={(e) => {
          setIsVisited(true);
          if (validateFunction) setIsValid(validateFunction(value ? value : internalValue));
          if (typeof props.onBlur === "function") props.onBlur(e, isValid);
        }}
        value={(value ? value : internalValue) ?? ""}
        required={required}
      />
      {shouldShowError && <p className="text-red-400">{errorMessage}</p>}
    </div>
  );
}

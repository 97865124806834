import React, { useState } from "react";
import classNames from "classnames";
import Select from "./form/Select";
import useFormHook from "../hooks/useForm";
import Button from "./form/Button";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_REPAIR } from "../graphql/queries";
import { useRepairContext } from "../contexts/RepairContext";
import { GrFormTrash } from "react-icons/gr";

export const StatusChanger = (props) => {
  const { id, status } = props;

  const [deleteClicked, setDeleteClicked] = useState(false);
  const form = useFormHook();
  const { refetchSingle } = useRepairContext();
  const [updateRepair, updateResponse] = useMutation(UPDATE_REPAIR);

  let allowedStates = [];
  if (status === "in_transport") {
    allowedStates = [{ label: "Neu erstellte", value: "created" }];
  }
  if (status === "created" || status === "irrepairable") {
    allowedStates = [{ label: "In Bearbeitung", value: "in_transport" }];
  }
  if (status === "in_transport") {
    allowedStates = [{ label: "Unreparierbar", value: "irrepairable" }];
  }
  if (status === "accepted") {
    allowedStates = [{ label: "Reparatur wird durchgeführt", value: "in_repair" }];
  }
  if (status === "rejected") {
    allowedStates = [
      { label: "Akzeptiert", value: "accepted" },
      { label: "Angebot vorhanden", value: "cost_estimate_available" },
    ];
  }

  return (
    <div
      className={classNames("relative pb-10 text-lg  border-gray-300 border-solid", {
        "border-b": allowedStates.length > 0,
      })}
    >
      {allowedStates.length > 0 && (
        <form
          className="flex items-end space-x-4"
          onSubmit={async (e) => {
            e.preventDefault();
            const newStatus = form.formValues.status;
            if (newStatus === status) return;
            await updateRepair({
              variables: {
                id,
                set: { status: newStatus },
              },
            });
            refetchSingle(id);
          }}
        >
          <Select
            className="w-1/3 mb-0"
            options={allowedStates}
            id="status"
            label="Status setzen auf"
            placeholder="Bitte auswählen"
            defaultValue={props.status}
            {...form}
          />
          <Button type="submit">Status ändern</Button>
        </form>
      )}
      <div className="absolute top-0 right-0">
        <button
          className="p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0"
          onClick={async () => {
            if (window.confirm("Willst du diese Reparatur wirklich löschen?")) {
              await updateRepair({
                variables: {
                  id,
                  set: { deleted: true },
                },
              });
            }
          }}
        >
          <span className="block text-3xl text-black bg-transparent h-7 w-7">
            <GrFormTrash />
          </span>
        </button>
      </div>
    </div>
  );
};
